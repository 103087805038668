import img from "../../images/other_services/trash._jpg_.jpg";
import Service from "../../components/Service";
import {pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import Downbar from "../../components/down_footer_pulizie";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <Downbar></Downbar>
      <Banner pageName="Rotazioni sacchi " prepageName="Servizio" postpageName="Rotazioni sacchi e raccolta differenziata" />
      <Service
        title="ROTAZIONE SACCHI"
        text="Manutenpul offre un servizio di rotazione sacchi condominio."
        img={img}
      >
      <br />
      <br />
      <p style={{"color":"#000"}}>Il servizio rotazione sacchi condominio include il trasporto giornaliero dei bidoni e sacchi, per una corretta gestione dei rifiuti.</p>
      <br />
      <br />
      <p style={{"color":"#000"}}>Il servizio rotazione sacchi condominio viene fatto nell’orario indicato dagli enti più vicino.</p>
      <br />
      <br />
      <p style={{"color":"#000"}}>Inoltre, il servizio rotazione sacchi Manutenpul include la pulizia dei locali pattumiera. Possiamo anche fornire un servizio di sanificazione</p>
      <br />
      <br />
      <p style={{"color":"#000"}}>Il servizio è offerto anche come supporto per il custode condominiale. Per qualsiasi esigenza siamo a vostra disposizione.</p>
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
